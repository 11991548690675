//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-704:_3184,_4920,_1258,_2880,_404,_9378,_816,_7096;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-704')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-704', "_3184,_4920,_1258,_2880,_404,_9378,_816,_7096");
        }
      }catch (ex) {
        console.error(ex);
      }