
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button',
        'title': 'Garage: ' + this.size + ' item(s)',
        'tabIndex': '0',
        'role': 'button'
    }, _createElement('div', { 'className': 'garage-icon-container' }, _createElement('div', { 'className': 'garage-icon cm_icon_garage__background' }), this.size ? [_createElement('span', {
            'className': 'garage-size site-header-cart--count visible',
            'key': 'garage-size',
            'data-header-cart-count': this.size
        })] : null), _createElement('span', { 'className': 'garage-label' }, 'Garage')), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item' }, _createElement('span', {
                            'className': 'select-model',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 22 22',
                                'key': '1910'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '31'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage'
            }, 'Nullstill Garasje'), _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle  cm_button button-primary cm_addButton' }, ['Legg til et kjøretøy']);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '1406'
            }, _createElement('div', { 'className': 'garage-content' }, [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle  cm_button button-primary cm_addButton' }, ['Legg til et kjøretøy']);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('ul', {}, _createElement('li', { 'className': 'vehicle-advantages' }, 'Oppbevar kjøretøyet ditt i garasjen'), _createElement('li', { 'className': 'vehicle-advantages' }, 'Få produkter til kjøretøyet ditt'), _createElement('li', { 'className': 'vehicle-advantages' }, 'Finn enkelt delene du trenger')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:addButton","cm:addButton","cm:garageDropdown"]