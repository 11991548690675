export default {
  SEARCH_BOX_PLACEHOLDER: 'Hva ser du etter?',
  SEARCH_BOX_TITLE: 'Søk',
  SEARCH_BOX_SECTION_TITLE:
    "<b>{this.totalHits > 1 ? `${this.totalHits} ` : ''}{this.sectionTitle === 'Products' ? 'Produkter' : this.sectionTitle}</b>{!!this.vehicleString ? ` für ${this.vehicleString}` : ''}",
  SEARCH_BOX_CLEAR_BUTTON: `{window.innerWidth > 768 ? '✕' : 'Nullstill'}`,
  BROWSE_ALL: 'Bla gjennom alle produktene',

  FILTERS_TEXT: 'Filter',
  FACET_DIALOG_TITLE: 'Filter:',
  FACET_DIALOG_CLEAR: 'Nullstill',
  FACET_DIALOG_DONE: 'Ferdig',
  FACET_DIALOG_RESULTS: 'Resultater',

  TOTAL_HITS: 'Resultater',
  SORT_BY: 'Sorter etter:&nbsp;',
  CURRENT_SEARCH: 'Gjeldende søk:',
  PRICE_FACET_GO_TEXT: 'Søk',
  SELECTED_CUSTOM_RANGE_TO: 'til',
  MAX_PRICE: 'Maks',
  FACET_FILTER_PLACEHOLDER: 'Velg',
  FACET_FILTER_NOTHING: 'Ingen oppføringer funnet',
  SHOW_LESS: 'Vis mindre',
  SHOW_MORE: 'Vis mer',
  START_OVER: 'Nullstill',

  FITMENT_TABLE_MANY_FITMENTS:
    'Viser for øyeblikket 1000 tilpasninger, men produktet har flere tilpasninger.',
  FITMENT_UNIVERSAL_TITLE: 'Universelt',

  CLEAR_GARAGE_TEXT: 'Nullstill Garasje',
  GET_PRODUCTS_FOR_VEHICLE: 'Få produkter til kjøretøyet ditt',
  FIND_PARTS_YOU_NEED: 'Finn enkelt delene du trenger',
  STORE_VEHICLE_IN_GARAGE: 'Oppbevar kjøretøyet ditt i garasjen',

  SELECT_YOUR_VEHICLE: 'Velg kjøretøyet ditt',
  SELECT_YOUR_VEHICLE_BLOCK: 'Velg kjøretøyet ditt',
  ADD_VEHICLE: 'Legg til et kjøretøy',
  CLEAR_TEXT: 'Nullstill',
  GO_TEXT: 'Søk',
  CHANGE_TEXT: 'Endre',
  CHANGE_VEHICLE: 'Endre',
  DISCARD_VEHICLE: 'Nullstill',
  VEHICLE_INFO_TITLE: 'Kjøretøy Felg/Dekk Info',

  VERIFY_FITMENT_TITLE: 'Sjekk om dette produktet passer din bil',
  VERIFY_FITMENT_FITS: 'Dette produktet passer din',
  VERIFY_FITMENT_NOT_FIT: 'Dette produktet passer IKKE din',
  VERIFY_FITMENT_COMPATIBLE_PARTS: 'Klikk her for å finne produkter som passer kjøretøyet ditt',
  FITMENT_UNKNOWN_TITLE: 'Ingen tilpasningsdata',
  FITMENT_UNKNOWN_TEXT:
    'Dette produktet har ikke tilpasningsapplikasjonsdata. Vennligst kontroller tilpasning manuelt.',

  SELECT_TITLE: `{window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title}`,
  SELECT_LOADING: '...Laster inn...',

  LOAD_MORE_TEXT: 'Neste {{ nextPageSize }}',
};
