import shopifyEmpireGenericDefaults from '../shopify-empire-generic/config.js';

const wheelsFields = shopifyEmpireGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyEmpireGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

globalThis.Convermax.fitmentFieldsTranslation = {
  'Year': 'Årsmodell',
  'Make': 'Merke',
  'Model': 'Modell',
  'ModelType': 'Type',
  'Type': 'Type', // display name of the ModelType field
  'Submodel': 'Undermodell',
  'Width': 'Bredde',
  'Aspect': 'Profil',
  'Rim': 'Diameter',
  'Bolt_Pattern': 'Boltsirkel',
  'Diameter': 'Diameter',
  'Upsize/Downsize': 'Størrelse større/Størrelse mindre',
};

const getActiveCurrency = () => globalThis.Shopify.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getActiveCurrency(),
    maximumFractionDigits: 0,
  }).format(price);
}

const getFitmentSearchTitle = () =>
  window.document.querySelector('#cm-collection-list')
    ? 'Kategorier'
    : window.document.querySelector('#cm-brand-list')
      ? 'Merker'
      : '';

export default {
  includes: ['shopify-empire-generic'],
  ...shopifyEmpireGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyEmpireGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyEmpireGenericDefaults.SearchRequestDefaults.extra,
      doNotHideWheelsTiresFacets: window.location.pathname === '/',
    },
  },
  product: {
    ...shopifyEmpireGenericDefaults.product,
    formatPrice,
  },
  fitmentSearch: {
    ...shopifyEmpireGenericDefaults.fitmentSearch,
    getFitmentSearchTitle,
  },
  Widgets: [
    ...shopifyEmpireGenericDefaults.Widgets.filter(
      (w) =>
        !['SearchHeader', 'HeaderVehicleWidget', 'FitmentTable', 'FacetBarWheels', 'FacetBarTires'].includes(
          w.name,
        ),
    ),
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 770,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Bestselger',
        'title': 'Navn, A-Z',
        'title:desc': 'Navn, Z-A',
        'collection_sort': 'Utvalgte',
        'price': 'Pris, lav til høy',
        'price:desc': 'Pris, høy til lav',
        'published_at': 'Dato, gammelt til nytt',
        'published_at:desc': 'Dato, nytt til gammelt',
      },
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-wheel-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-tire-tab',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: tiresFields,
    },

    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'VehicleInfo',
      initCollapsed: true,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
  ],
};
