
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function HomeWheelTireBarRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3437'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3639'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '8341'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '8344'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2755'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...Laster inn...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__wheel-tire cm_row cm_vehicle-widget__row' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns cmTemplate_active' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('button', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button button-primary',
        'onClick': this.submit,
        'disabled': !this.hasSelection
    }, '\n          Søk\n        '), _createElement('button', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button button-secondary',
        'onClick': this.clear,
        'disabled': this.disableClear
    }, '\n          Nullstill\n        ')))));
}
        export const componentNames = []