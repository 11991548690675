import { useCallback } from 'react';

import { useResizeState } from 'Core/hooks/index.js';
import { cloneSafe } from 'Utils/components.ts';

import type { FC } from 'react';
import type { TemplateFunction, RepeaterFunctionInvoker } from 'Components/types.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

enum layoutType {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

interface ItemParams {
  fields: string[];
}

export interface Params {
  items: RepeaterFunctionInvoker<ItemParams>;
  template: keyof typeof layoutType;
}

interface Props {
  template: TemplateFunction<Params>;
  fields: string[];
  data: Record<string, string>[] | ServerModel.GenericItem[];
  extraProps?: Record<string, unknown>;
  columnBreakpoint?: number;
}

const Table: FC<Props> = ({ template, fields, data, extraProps = {}, columnBreakpoint = 425 }) => {
  const layout = useResizeState(
    useCallback(
      () => (window.innerWidth > columnBreakpoint ? layoutType.horizontal : layoutType.vertical),
      [columnBreakpoint],
    ),
  ) as keyof typeof layoutType;

  if (!data?.length) {
    return null;
  }

  const tableTitleData = Object.fromEntries(fields.map((field) => [field, field]));

  const items = [
    (templ) => cloneSafe(templ.call({ ...tableTitleData, fields }), null, { key: 'title' }),
    ...data.map((row) => (templ: TemplateFunction<ItemParams>) => {
      const key = Object.entries(row)
        .map((pair) => `${pair[0]}|${pair[1]}`)
        .join('&');
      const component = templ.call({ ...row, fields });
      return cloneSafe(component, null, { key });
    }),
  ] as RepeaterFunctionInvoker<ItemParams>;

  return template.call({ items, template: layout, ...extraProps });
};

export default Table;
