import { useRef } from 'react';
import { useSelector } from 'react-redux';

import Table from 'Components/smallComponents/table.tsx';
import { useCollapsible } from 'Core/hooks/index.js';
import { searchResponseExtraSelector } from 'Core/selectors/search.js';
import isMobile from 'Vendor/isMobile.js';
import { cloneSafe } from 'Utils/components.ts';

import type { FC } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';

interface VehicleInfoExtra {
  Fields: string[];
  Info: Record<string, string>[];
}

interface Params {
  table: TemplateFunctionInvoker<unknown>;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

interface Props {
  template: TemplateFunction<Params>;
  initCollapsed?: boolean;
  columnBreakpoint?: number;
}

const VehicleInfo: FC<Props> = ({ template, initCollapsed, columnBreakpoint = 768 }) => {
  const rootRef = useRef<HTMLElement>(null);
  const [collapsed, toggleCollapsed] = useCollapsible(rootRef, null, initCollapsed ?? isMobile);

  const vehicleInfo = useSelector(searchResponseExtraSelector)?.VehicleInfo as VehicleInfoExtra;

  if (!vehicleInfo || !vehicleInfo.Fields || !vehicleInfo.Info) {
    return null;
  }

  const table = (templ: TemplateFunction<unknown>) => {
    const notEmptyFields = getNotEmptyFields(vehicleInfo.Fields, vehicleInfo.Info);
    const props = { template: templ, fields: notEmptyFields, data: vehicleInfo.Info, columnBreakpoint };
    return (<Table {...props} />) as TemplateResult;
  };

  const component = template.call({ table, collapsed, toggleCollapsed });

  return cloneSafe(component, rootRef);
};

function getNotEmptyFields(fields: string[], data: Record<string, string>[]) {
  const emptyFields = new Set(fields);

  data.some((item) => {
    [...emptyFields].forEach((field) => {
      if (item[field]) {
        emptyFields.delete(field);
      }
    });

    return emptyFields.size === 0;
  });

  return fields.filter((field) => !emptyFields.has(field));
}

export default VehicleInfo;
