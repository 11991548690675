//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-704:_7280,_9696,_5848,_7104,_5978,_16,_3300,_136;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-704')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-704', "_7280,_9696,_5848,_7104,_5978,_16,_3300,_136");
        }
      }catch (ex) {
        console.error(ex);
      }